import { Stack } from "@mui/material";
import MemberWeekViewSelector from "./MemberWeekViewSelector";
import styles from "./_MemberWeekView.module.scss";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { emitEvent, setListener, unsetListener } from "../../index.js";
import MemberWeekViewCell from "./MemberWeekViewCell.jsx";
import EditHoursDialog from "./EditHoursDialog.jsx";

const weekdayAbbr = ["MO", "DI", "MI", "DO", "FR", "SA", "SO"];

export default function MemberWeekView(props) {
  const startDate = moment().startOf("isoweek");
  if (startDate.isoWeekday() >= 5) {
    startDate.add(1, "weeks");
  }

  const [currentStartDate, setCurrentStartDate] = useState(startDate);
  const [dates, setDates] = useState([]);
  const [dateHoursList, setDateHoursList] = useState([]);
  const [memberWeekViewCells, setMemberWeekViewCells] = useState([]);

  const [dialogDateHours, setDialogDateHours] = useState(null);

  const updateDateHours = (newDateHours) => {
    setDateHoursList((data) => {
      let targetIndex = data.length;
      let newData = [...data];
      data.forEach((dateHours, index) => {
        if (
          moment(dateHours.H_Date).format("YYYY-MM-DD") ===
            newDateHours.H_Date &&
          dateHours.M_ID === newDateHours.M_ID
        ) {
          targetIndex = index;
        }
      });
      newData[targetIndex] = newDateHours;
      return newData;
    });
  };

  const parseDateHoursList = (serverDateHoursList) => {
    let newDateHoursList = [];
    for (let dateHours of serverDateHoursList) {
      let dateHoursEntry = {
        M_ID: dateHours.M_ID,
        H_Notes: dateHours.H_Notes,
        H_Date: moment(dateHours.H_Date).format("YYYY-MM-DD"),
        H_StartTime: moment(dateHours.H_StartTime, "HH:mm:ss"),
        H_EndTime: moment(dateHours.H_EndTime, "HH:mm:ss"),
        H_BreakDuration: moment(dateHours.H_BreakDuration, "HH:mm:ss"),
      };
      try {
        dateHoursEntry.H_Props = JSON.parse(dateHours.H_Props);
      } catch {}
      newDateHoursList.push(dateHoursEntry);
    }
    setDateHoursList(newDateHoursList);
  };

  useEffect(() => {
    setListener("selectDates", setDates);
    setListener("selectDateHours", parseDateHoursList);

    if (props.loggedIn) {
      let start = currentStartDate;
      let end = currentStartDate.clone().add(1, "weeks");

      emitEvent(
        "selectDates",
        start.format("YYYY-MM-DD"),
        end.format("YYYY-MM-DD")
      );

      emitEvent(
        "selectDateHours",
        start.format("YYYY-MM-DD"),
        end.format("YYYY-MM-DD"),
        props.currentUser.M_ID
      );
    }
    
    return () => {
      unsetListener("selectDates");
      unsetListener("selectDateHours");
    };
  }, [currentStartDate, props.loggedIn, props.currentUser.M_ID]);

  const changeDate = useCallback(
    (property, value) => {
      let tempMoment = moment(currentStartDate).add(value, property);

      if (
        tempMoment.isoWeek() === moment().isoWeek() ||
        (moment().isoWeekday() >= 5 &&
          tempMoment.isoWeek() <= moment().add(1, "weeks").isoWeek()) || //If it's Friday and requested week is next week
        (tempMoment.isBefore(moment()) &&
          (tempMoment.isAfter(moment().startOf("month").subtract(3, "days")) || //If requested week belongs to current month (1st date is wednesday or lower)
            (moment().date() <= 5 &&
              tempMoment.isAfter(
                moment()
                  .subtract(1, "month")
                  .startOf("month")
                  .subtract(3, "days")
              )))) //If date is before 5th and requested week is within one month prior
      ) {
        setCurrentStartDate((d) => moment(d).add(value, property));
      }
    },
    [currentStartDate]
  );

  useEffect(() => {
    let newMemberWeekViewCells = [];
    let currentDate = currentStartDate.clone();

    for (let i = -1; i < 7; i++) {
      const tDate = currentDate.format("YYYY-MM-DD");
      const mID = props.currentUser.M_ID;
      const tWeekInfo = i === -1 ? 1 : 0;

      let dateEntry = {
        T_Content: "",
        T_Date: tDate,
        M_ID: mID,
        T_WeekInfo: tWeekInfo,
      };

      let dateHoursEntry = {
        H_Date: tDate,
        H_StartTime: moment().hours(8).minutes(0),
        H_EndTime: moment().hours(16).minutes(0),
        H_BreakDuration: moment().hours(0).minutes(30),
        H_Notes: "",
        M_ID: mID,
        H_Props: {
          dayType: "-",
        },
        serverLoaded: false
      };

      //Legacy code for old IDs without 'D'
      for (let date of dates) {
        if (
          moment(date.T_Date).format("YYYY-MM-DD") === tDate &&
          date.M_ID === mID &&
          date.T_WeekInfo === tWeekInfo
        ) {
          dateEntry = date;
        }
      }

      for (let dateHours of dateHoursList) {
        if (
          moment(dateHours.H_Date).format("YYYY-MM-DD") === tDate &&
          dateHours.M_ID === mID
        ) {
          dateHours.serverLoaded = true;
          dateHoursEntry = dateHours;
        }
      }

      newMemberWeekViewCells.push(
        <div
          className={styles.listItem + " " + (i === -1 ? styles.special : "")}
          key={`${tDate}-${mID}-${tWeekInfo}`}
        >
          <div className={styles.listItemDate}>
            {i === -1 ? (
              <span>KFZ</span>
            ) : (
              <>
                <span className={styles.day}>
                  {weekdayAbbr[currentDate.isoWeekday() - 1]}
                </span>
                <span className={styles.date}>{currentDate.format("DD")}</span>
                <span className={styles.month}>{currentDate.format("MM")}</span>
              </>
            )}
          </div>
          <div className={styles.cellWrapper}>
            <MemberWeekViewCell
              dates={dates}
              date={dateEntry}
              projects={props.projects}
              darker={i % 2 === 1}
              special={i === -1}
              dateHours={dateHoursEntry}
              setDialogDateHours={setDialogDateHours}
            />
          </div>
        </div>
      );

      if (i >= 0) {
        currentDate.add(1, "days");
      }
    }

    setMemberWeekViewCells(newMemberWeekViewCells);
  }, [
    currentStartDate,
    dateHoursList,
    dates,
    props.currentUser.M_ID,
    props.projects,
  ]);

  return (
    <div className={styles.memberWeekViewWrapper}>
      <MemberWeekViewSelector changeDate={changeDate} date={currentStartDate} />
      <Stack>{memberWeekViewCells}</Stack>
      <EditHoursDialog
        dialogDateHours={dialogDateHours}
        setDialogDateHours={setDialogDateHours}
        updateDateHours={updateDateHours}
      />
    </div>
  );
}
