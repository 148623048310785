import { useCallback, useEffect, useRef, useState } from "react";
import { emitEvent, setListener, unsetListener } from "../index.js";
import moment from "moment";
import styles from "./_MemberTable.module.scss";
import MemberHead from "./MemberHead.js";
import MemberRow from "./MemberRow.js";
import MemberTableSelector from "./MemberTableSelector.js";
import CalendarHead from "./CalendarHead.js";

export default function MemberTable(props) {
  const startDate = moment();
  if (startDate.isoWeekday() >= 5) {
    startDate.add(1, "weeks").startOf("isoweek");
  }

  const [members, setMembers] = useState([]);

  const memberTable = useRef(null);
  const memberTableWrapper = useRef(null);

  const currentWeekPosition = useRef(null);
  const rowHeadWidth = useRef(null);

  const [scrolled, setScrolled] = useState(false);
  const [sortedDates, setSortedDates] = useState(null);
  const [displayReady, setDisplayReady] = useState(null);
  const [date, setDate] = useState(startDate);
  const [activeSelection, setActiveSelection] = useState([]);
  const [activeSelectionStart, setActiveSelectionStart] = useState([]);
  const [selectionBoxStyle, setSelectionBoxStyle] = useState({
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  });
  const [selectedProject, setSelectedProject] = useState({
    P_ID: -1,
    P_Name: "",
    P_Color: "",
  });
  //TODO initialize screenSize with small when window <= 500
  const [screenSize, setScreenSize] = useState({
    width: "large",
    height: "large",
  });

  const getFirstDate = useCallback(() => {
    let firstWeekDayDate = moment(date).date(1);
    let firstDayOfWeek = firstWeekDayDate.isoWeekday();

    //If first day in month is previous thursday, then show week, otherwise start with next week
    if (firstDayOfWeek < 4) {
      firstWeekDayDate.startOf("isoWeek");
    } else {
      firstWeekDayDate.add(7, "days").startOf("isoWeek");
    }

    return firstWeekDayDate;
  }, [date]);

  const getNumberOfWeeks = useCallback(() => {
    let firstDate = getFirstDate();

    let lastDate = moment(date).endOf("month");

    let weeks;

    //If last day in month is after tuesday, then show week, otherwise end with previous week
    if (lastDate.isoWeekday() > 2) {
      weeks =
        Math.abs(firstDate.diff(lastDate.startOf("isoWeek"), "days")) / 7 + 1;
    } else {
      weeks = Math.abs(firstDate.diff(lastDate.startOf("isoWeek"), "days")) / 7;
    }

    return weeks;
  }, [date, getFirstDate]);

  let firstDate = getFirstDate().toDate();
  let numberOfWeeks = getNumberOfWeeks();

  setListener("updateCellEntry", (date) => {
    let T_Date = moment(date.T_Date);

    var duration = moment.duration(getFirstDate().diff(T_Date));
    var days = Math.abs(Math.floor(duration.asDays())) + 1;

    let prevSortedDates = sortedDates;

    for (var i = 0; i < members.length; i++) {
      if (members[i].M_ID === date.M_ID) {
        prevSortedDates[i][days] = date;
      }
    }

    setSortedDates(prevSortedDates);
  });

  //Scroll to current week when sortedDates change
  useEffect(() => {
    if (!sortedDates) return;
    if (scrolled) return;
    if (!memberTableWrapper.current) return;
    if (!rowHeadWidth.current) return;
    if (!currentWeekPosition.current) return;
    if (!moment(date).isSame(moment(), "month")) return;

    setScrolled(true);

    memberTableWrapper.current.scrollLeft =
      currentWeekPosition.current.offsetLeft - rowHeadWidth.current.offsetWidth;
  }, [
    sortedDates,
    memberTableWrapper,
    rowHeadWidth,
    currentWeekPosition,
    date,
    scrolled,
  ]);

  //Filter out members who left in past
  useEffect(() => {
    setMembers(
      props.members.filter(
        (m) =>
          !m.M_LeaveDate ||
          moment(m.M_LeaveDate).isAfter(date.clone().startOf("M"))
      )
    );
  }, [props.members, date]);

  // #region Date Functions

  //Select Dates From Server

  const changeDate = useCallback(
    (property, value) => {
      //Checkt ob Nutzer Admin ist ODER (Die neue Woche die aktuelle Woche ist) ODER (Die neue Woche vor der aktuellen liegt UND der heutige Tag mindestens Freitag ist UND die neue Woche nicht nach der nächsten Woche liegt)

      setDisplayReady(false);
      let tempMoment = moment(date);
      setDate(tempMoment.add(value, property));
    },
    [date]
  );

  //Sort dates into multidimensional array to later divide into individual chunks
  const sortDates = useCallback(
    (dates) => {
      if (members == null) return;

      let sortedDates = new Array(members.length);
      let firstDate = getFirstDate();
      let numberOfWeeks = getNumberOfWeeks();
      let totalCellsInRow = numberOfWeeks * 8; //7 days per week + 1 week info cell

      for (let mID = 0; mID < members.length; mID++) {
        let currentDate = getFirstDate();
        sortedDates[mID] = new Array(totalCellsInRow);

        for (let cell = 0; cell < totalCellsInRow; cell++) {
          //set type according to cell (week info or normal day)
          let tWeekInfo = cell % 8 === 0 ? 1 : 0;

          let emptyDate = {
            T_Date: currentDate.toDate(),
            T_Content: "",
            P_ID: -1,
            M_ID: members[mID].M_ID,
            rowIndex: mID,
            columnIndex: cell,
            T_WeekInfo: tWeekInfo,
          };
          sortedDates[mID][cell] = emptyDate;

          //if cell is week info cell, do not count day up
          if (cell % 8 === 0) continue;

          currentDate.add(1, "days");
        }
      }

      //iterate over retrieved dates from database and sort them in
      for (let j = 0; j < dates.length; j++) {
        let T_Date = moment(dates[j].T_Date);

        //calculate days difference between first date in row and T_Date
        let column = T_Date.diff(firstDate, "days");
        column += Math.floor(column / 7);
        if (!dates[j].T_WeekInfo) column++;

        for (let row = 0; row < members.length; row++) {
          if (members[row].M_ID === dates[j].M_ID) {
            sortedDates[row][column] = dates[j];
            sortedDates[row][column].rowIndex = row;
            sortedDates[row][column].columnIndex = column;
          }
        }
      }

      setDisplayReady(true);

      setSortedDates(sortedDates);
    },
    [getFirstDate, getNumberOfWeeks, members]
  );

  const selectDatesFromServer = useCallback(() => {
    let start = getFirstDate().subtract(1, "days");
    let end = start.clone().add(getNumberOfWeeks() * 7, "days");
    setDisplayReady(false);
    setScrolled(false);
    emitEvent(
      "selectDates",
      start.format("YYYY-MM-DD"),
      end.format("YYYY-MM-DD")
    );
  }, [getFirstDate, getNumberOfWeeks]);

  useEffect(() => {
    setListener("selectDates", sortDates);

    if (props.loggedIn) {
      selectDatesFromServer();
    }

    return () => {
      unsetListener("selectDates");
    };
  }, [props.loggedIn, selectDatesFromServer, sortDates]);
  // #endregion

  // #region Selection Functions

  const changeSelectionBox = (coords1, coords2) => {
    let newStyle = {};
    Object.assign(newStyle, selectionBoxStyle);
    newStyle.left = Math.min(coords1.left, coords2.left);
    newStyle.right = Math.max(coords1.right, coords2.right);
    newStyle.top = Math.min(coords1.top, coords2.top);
    newStyle.bottom = Math.max(coords1.bottom, coords2.bottom);

    if (
      newStyle.left !== selectionBoxStyle.left ||
      newStyle.top !== selectionBoxStyle.top ||
      newStyle.right !== selectionBoxStyle.right ||
      newStyle.bottom !== selectionBoxStyle.bottom
    ) {
      setSelectionBoxStyle(newStyle);
    }
  };

  const changeSelection = (date, mode, coords) => {
    let newActiveSelection = [];
    let newActiveSelectionStart = activeSelectionStart;

    if (mode === 1) {
      newActiveSelectionStart = [date.rowIndex, date.columnIndex, coords];
    }

    changeSelectionBox(newActiveSelectionStart[2], coords);

    for (
      var row = Math.min(newActiveSelectionStart[0], date.rowIndex);
      row <= Math.max(newActiveSelectionStart[0], date.rowIndex);
      row++
    ) {
      for (
        var column = Math.min(newActiveSelectionStart[1], date.columnIndex);
        column <= Math.max(newActiveSelectionStart[1], date.columnIndex);
        column++
      ) {
        newActiveSelection.push([row, column]);
      }
    }
    setActiveSelection(newActiveSelection);
    setActiveSelectionStart(newActiveSelectionStart);
  };
  const copySelected = useCallback(() => {
    if (activeSelection.length > 0) {
      var copiedText = "";
      var copiedDates = "";
      var i = 0;
      for (
        var row = activeSelection[0][0];
        row <= activeSelection[activeSelection.length - 1][0];
        row++
      ) {
        if (row > activeSelection[0][0]) {
          copiedText = copiedText.concat("\n");
          copiedDates = copiedDates.concat("\n");
        }
        var column = activeSelection[0][1];

        let columnShiftLength = 0; //Because of hidden days
        while (
          column <=
          activeSelection[activeSelection.length - 1][1] + columnShiftLength
        ) {
          console.log(column);
          if (column > activeSelection[0][1]) {
            copiedText = copiedText.concat("\t");
            copiedDates = copiedDates.concat("\t");
          }
          console.log(copiedText);
          copiedText = copiedText.concat(
            sortedDates[activeSelection[i][0]][activeSelection[i][1]].T_Content
          );
          copiedDates = copiedDates.concat(
            JSON.stringify(
              sortedDates[activeSelection[i][0]][activeSelection[i][1]]
            )
          );
          i++;
          column++;
        }
      }
      localStorage.setItem("clipboardDates", copiedDates);
      localStorage.setItem("clipboardText", copiedText);
      navigator.clipboard.writeText(copiedText);
    }
  }, [activeSelection, sortedDates]);

  const pasteFromClipboard = useCallback(() => {
    if (activeSelection.length > 0) {
      let copiedDates = [];
      let newSortedDates = sortedDates;

      navigator.clipboard.readText().then((clipboardText) => {
        // Insertion of copied String into copiedDates Matrix
        let rows = "";
        if (
          localStorage.getItem("clipboardDates") &&
          localStorage.getItem("clipboardDates") !== "" &&
          localStorage.getItem("clipboardText") ===
            clipboardText.replaceAll("\r\n", "\n")
        ) {
          rows = localStorage.getItem("clipboardDates").split("\n");
        } else {
          rows = clipboardText.split("\n");
        }
        copiedDates = new Array(rows.length);
        for (let row = 0; row < rows.length; row++) {
          var columns = rows[row].split("\t");
          copiedDates[row] = new Array(columns.length);
          for (let column = 0; column < columns.length; column++) {
            if (columns[column] == null) {
              columns[column] = "";
            }
            copiedDates[row][column] = columns[column];
          }
        }

        var rowsSelected =
          activeSelection[activeSelection.length - 1][0] -
          activeSelection[0][0] +
          1;
        var columnsSelected =
          activeSelection[activeSelection.length - 1][1] -
          activeSelection[0][1] +
          1;
        for (
          var multiplyRows = 0;
          multiplyRows <
          Math.max(1, Math.floor(rowsSelected / copiedDates.length));
          multiplyRows++
        ) {
          var copiedDatesRow = 0;
          let row = activeSelection[0][0];
          while (row < activeSelection[0][0] + copiedDates.length) {
            let columnShiftLength = 0; //Because of hidden days
            for (
              var multiplyColumns = 0;
              multiplyColumns <
              Math.max(1, Math.floor(columnsSelected / copiedDates[0].length));
              multiplyColumns++
            ) {
              var copiedDatesColumn = 0;
              let column = activeSelection[0][1] + columnShiftLength;
              while (
                column <
                activeSelection[0][1] +
                  copiedDates[0].length +
                  columnShiftLength
              ) {
                //Check if Member has permission to paste to cell
                if (
                  props.currentUser.M_Admin ||
                  (props.currentUser.M_EditSelf &&
                    newSortedDates[row + multiplyRows * copiedDates.length][
                      column + multiplyColumns * copiedDates[0].length
                    ].M_ID === props.currentUser.M_ID) ||
                  (props.currentUser.M_EditSubs &&
                    newSortedDates[row + multiplyRows * copiedDates.length][
                      column + multiplyColumns * copiedDates[0].length
                    ].M_ID === props.currentUser.M_ID)
                ) {
                  if (
                    localStorage.getItem("clipboardText") &&
                    clipboardText.replaceAll("\r\n", "\n") ===
                      localStorage.getItem("clipboardText")
                  ) {
                    //Is origin of copied text this program?
                    let parseDate;

                    try {
                      parseDate = JSON.parse(
                        copiedDates[copiedDatesRow][copiedDatesColumn]
                      );
                      if (
                        newSortedDates[
                          row + multiplyRows * copiedDates.length
                        ] &&
                        newSortedDates[row + multiplyRows * copiedDates.length][
                          column + multiplyColumns * copiedDates[0].length
                        ]
                      ) {
                        newSortedDates[row + multiplyRows * copiedDates.length][
                          column + multiplyColumns * copiedDates[0].length
                        ].T_Content = parseDate.T_Content;
                        newSortedDates[row + multiplyRows * copiedDates.length][
                          column + multiplyColumns * copiedDates[0].length
                        ].P_ID = parseDate.P_ID;
                      }
                    } catch (e) {
                      if (
                        newSortedDates[
                          row + multiplyRows * copiedDates.length
                        ] &&
                        newSortedDates[row + multiplyRows * copiedDates.length][
                          column + multiplyColumns * copiedDates[0].length
                        ]
                      ) {
                        newSortedDates[row + multiplyRows * copiedDates.length][
                          column + multiplyColumns * copiedDates[0].length
                        ].T_Content =
                          copiedDates[copiedDatesRow][copiedDatesColumn];
                      }
                    }
                  } else {
                    if (
                      newSortedDates[row + multiplyRows * copiedDates.length] &&
                      newSortedDates[row + multiplyRows * copiedDates.length][
                        column + multiplyColumns * copiedDates[0].length
                      ]
                    ) {
                      newSortedDates[row + multiplyRows * copiedDates.length][
                        column + multiplyColumns * copiedDates[0].length
                      ].T_Content =
                        copiedDates[copiedDatesRow][copiedDatesColumn];
                    }
                  }
                  if (
                    newSortedDates[row + multiplyRows * copiedDates.length] &&
                    newSortedDates[row + multiplyRows * copiedDates.length][
                      column + multiplyColumns * copiedDates[0].length
                    ]
                  ) {
                    emitEvent(
                      "updateCellEntry",
                      newSortedDates[row + multiplyRows * copiedDates.length][
                        column + multiplyColumns * copiedDates[0].length
                      ]
                    );
                  }
                }
                column++;
                copiedDatesColumn++;
              }
            }
            row++;
            copiedDatesRow++;
          }
        }

        setSortedDates([...newSortedDates]);
      });
    }
  }, [activeSelection, props.currentUser, sortedDates]);

  const pasteToSelected = useCallback(
    (str, overwrite) => {
      if (activeSelection.length > 0) {
        let parseObject;

        try {
          parseObject = JSON.parse(str);
        } catch (e) {
          parseObject = false;
        }

        var newSortedDates = sortedDates;

        var selected = activeSelection;

        for (var i = 0; i < selected.length; i++) {
          //Check if Member has permission to paste to cell
          if (
            props.currentUser.M_Admin ||
            (props.currentUser.M_EditSelf &&
              newSortedDates[selected[i][0]][selected[i][1]].M_ID ===
                props.currentUser.M_ID) ||
            (props.currentUser.M_EditSubs &&
              newSortedDates[selected[i][0]][selected[i][1]].M_ID ===
                props.currentUser.M_ID)
          ) {
            if (
              parseObject &&
              parseObject.T_Content !== undefined &&
              (newSortedDates[selected[i][0]][selected[i][1]].T_Content ===
                "" ||
                overwrite)
            ) {
              newSortedDates[selected[i][0]][selected[i][1]].T_Content =
                parseObject.T_Content;
            }
            if (parseObject && parseObject.P_ID !== undefined) {
              newSortedDates[selected[i][0]][selected[i][1]].P_ID =
                parseObject.P_ID;
            }
            if (!parseObject) {
              newSortedDates[selected[i][0]][selected[i][1]].T_Content = str;
            }

            emitEvent(
              "updateCellEntry",
              newSortedDates[selected[i][0]][selected[i][1]]
            );
          }
        }

        setSortedDates([...newSortedDates]);
      }
    },
    [activeSelection, props.currentUser, sortedDates]
  );

  const changeSelectedProject = useCallback((project) => {
    setSelectedProject(project);
  }, []);

  const pasteProjectToSelected = useCallback(() => {
    pasteToSelected(
      JSON.stringify({
        P_ID: selectedProject.P_ID,
        T_Content: selectedProject.P_Name,
      }),
      false
    );
  }, [selectedProject, pasteToSelected]);

  const deleteSelected = useCallback(() => {
    const emptyDate = {
      T_Content: "",
      P_ID: -1,
    };

    pasteToSelected(JSON.stringify(emptyDate), true);
  }, [pasteToSelected]);
  // #endregion

  // #region Event listeners for resize and keydown
  const handleResize = useCallback(() => {
    if (window.innerWidth <= 500 && screenSize.width !== "small") {
      let newStyle = selectionBoxStyle;
      newStyle.left = newStyle.left * 0.9;
      newStyle.right = newStyle.right * 0.9;
      newStyle.top = newStyle.top * 0.9;
      newStyle.bottom = newStyle.bottom * 0.9;
      setSelectionBoxStyle(newStyle);
      setScreenSize({ width: "small", height: screenSize.height });
    } else if (window.innerWidth > 500 && screenSize.width !== "large") {
      let newStyle = selectionBoxStyle;
      newStyle.left = newStyle.left * 1.1111;
      newStyle.right = newStyle.right * 1.1111;
      newStyle.top = newStyle.top * 1.1111;
      newStyle.bottom = newStyle.bottom * 1.1111;
      setSelectionBoxStyle(newStyle);
      setScreenSize({ width: "large", height: screenSize.height });
    }
  }, [screenSize, selectionBoxStyle]);
  const handleKeyDown = useCallback(
    (event) => {
      let charCode = String.fromCharCode(event.which).toLowerCase();
      if (event.ctrlKey && charCode === "c") {
        copySelected();
        event.preventDefault();
      } else if (event.ctrlKey && charCode === "v") {
        pasteFromClipboard();
        event.preventDefault();
      } else if (event.ctrlKey && event.keyCode === 8) {
        deleteSelected();
        event.preventDefault();
      }

      // For MAC we can use metaKey to detect cmd key

      if (event.metaKey && charCode === "c") {
        copySelected();
        event.preventDefault();
      } else if (event.metaKey && charCode === "v") {
        pasteFromClipboard();
        event.preventDefault();
      } else if (event.metaKey && event.keyCode === 8) {
        deleteSelected();
        event.preventDefault();
      }
    },
    [copySelected, deleteSelected, pasteFromClipboard]
  );
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("keydown", handleKeyDown);
    };
  });

  // #endregion

  let memberRows = members.map((member, index) => (
    <MemberRow
      key={member.M_ID}
      member={member}
      screenSize={screenSize}
      currentUser={props.currentUser}
      dates={displayReady && sortedDates !== null ? sortedDates[index] : null}
      firstDay={firstDate}
      numberOfWeeks={numberOfWeeks}
      projects={props.projects}
      darker={index % 2 === 0}
      connected={props.connected}
      selectedList={activeSelection}
      changeSelection={changeSelection}
      deleteSelected={deleteSelected}
      pasteProjectToSelected={pasteProjectToSelected}
      openDialogue={props.openDialogue}
      changeSelectionBox={changeSelectionBox}
      copySelected={copySelected}
      pasteFromClipboard={pasteFromClipboard}
    />
  ));

  let testStyle = {
    clipPath: `polygon(${selectionBoxStyle.left}px ${selectionBoxStyle.top}px, ${selectionBoxStyle.right}px ${selectionBoxStyle.top}px, ${selectionBoxStyle.right}px ${selectionBoxStyle.bottom}px,${selectionBoxStyle.left}px ${selectionBoxStyle.bottom}px)`,
  };

  return (
    <>
      <MemberTableSelector
        projects={props.projects}
        date={date}
        changeDate={changeDate}
        refreshServer={props.refreshServer}
        connected={props.connected}
        changeSelectedProject={changeSelectedProject}
      />
      <div className={styles.memberTableWrapper} ref={memberTableWrapper}>
        <table ref={memberTable} className={styles.memberTable}>
          <div className={styles.selectionBox} style={testStyle}></div>
          <thead>
            <CalendarHead
              screenSize={screenSize}
              firstDay={firstDate}
              numberOfWeeks={numberOfWeeks}
              setRowHeadRef={rowHeadWidth}
              setWeekRef={currentWeekPosition}
            />
            <MemberHead firstDay={firstDate} numberOfWeeks={numberOfWeeks} />
          </thead>
          <tbody>{memberRows}</tbody>
        </table>
      </div>
    </>
  );
}
