import styles from "./_MemberWeekViewCell.module.scss";
import { shadeColor } from "../../index.js";
import { IconButton } from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";

export default function MemberWeekViewCell(props) {
  let style = {};
  let foundProject = null;

  props.date &&
    props.projects.forEach((project) => {
      if (props.date.P_ID !== -1 && project.P_ID === props.date.P_ID) {
        foundProject = project;
        const color = project.P_Color;
        const shadedColor = shadeColor(project.P_Color, -16);

        if (props.darker) {
          style.background = shadedColor;
        } else {
          style.background = color;
        }
      }
    });

  return (
    <div className={styles.memberWeekViewCell} style={style}>
      {props.date ? props.date.T_Content.replace(/(?:\r|\n|\r\n)/g, "\n") : ""}

      {!props.special ? (
        <IconButton
          sx={{
            position: "absolute",
            top: "50%",
            right: "2rem",
            transform: "translateY(-50%)",
            color: props.dateHours.serverLoaded ? "#59eb80" : "#2F2F38",
          }}
          color="primary"
          aria-label="edit hours"
          className={styles.editButton}
          onClick={() => {
            props.setDialogDateHours({...props.dateHours, foundProject: foundProject});
          }}
        >
          <ArticleIcon />
        </IconButton>
      ) : (
        <></>
      )}
    </div>
  );
}
