
import React, { Component } from "react";
import moment from 'moment';
import styles from "./_MemberTable.module.scss";

const days = [
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
    'Sonntag'
  ]

class WeekHead extends Component{

    render(){
        let firstDayDate = new Date(this.props.firstDay);

        let headDays = [];
        for(let i = 0; i < 7; i++){
            let currentDate = moment(firstDayDate).add(i, 'days');
            
            headDays.push((<th className={styles.headDay} key={days[currentDate.isoWeekday() - 1]}><div><div>{days[currentDate.isoWeekday() - 1]}</div><div>{currentDate.format('DD. MM. YYYY')}</div></div></th>));
            
        }

        return (
        <>
            <th className={styles.headSpecial}>KFZ</th>
            {headDays}
        </>
        );
    }
}


export default WeekHead;