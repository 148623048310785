import React, { Component } from 'react';
import { BiTrash } from 'react-icons/bi';
import { IoAdd } from 'react-icons/io5';
import {emitEvent, setListener} from "../index.js";


export default class SettingsPage extends Component{

    constructor(props){
        super(props);
            
        this.state = {
            inputField : {
                input_ID : -1,
                input_title : "",
                input_type : "Textfeld"
            },
            inputFieldList : []
        }

        setListener("inputFieldsUpdated", function(){
            emitEvent("selectInputFields");
        });


        setListener("selectInputFields", function(results){
            let inputFieldList = results.map(inputField => {
                return (
                    <div className="row" key={inputField.input_ID}>
                        <div className="inputField">
                            <div className="inputTitle">{inputField.input_title}</div>
                            <div className="inputType">{inputField.input_type}</div>
                            <button onClick={() => {this.handleDeleteInputField(inputField)}}><BiTrash/></button>
                        </div>
                    </div>
                );
            });

            this.setState({inputFieldList : inputFieldList});
        }.bind(this));

        this.handleChange = this.handleChange.bind(this);
        this.handleDeleteInputField = this.handleDeleteInputField.bind(this);
        this.addInputField = this.addInputField.bind(this);
    }

    componentDidMount(){
        emitEvent("selectInputFields");
    }

    handleDeleteInputField(inputField){
        if(window.confirm(inputField.input_title + " wirklich löschen?")){
            emitEvent("deleteInputField", inputField);
        }
    }


    handleChange(event){
        let inputField = this.state.inputField;

        inputField[event.target.name] = event.target.value;

        this.setState({
            inputField: inputField
        });
    }


    addInputField(event){
        event.preventDefault();
        if(this.state.inputField.input_title !== ""){
            emitEvent("updateInputField", this.state.inputField);
            this.setState({inputField: {
                input_ID : -1,
                input_title : "",
                input_type : "Textfeld"
            }})
        }
    }

    render(){
        return (
            <div className="tab">
                <div className="content medium">
                    <h2>Einstellungen</h2>
                    <h2>Allgemein</h2>
                    <div className="row">
                        <button onClick={this.props.logout}>Abmelden</button>
                    </div>
                    {this.props.currentUser.M_Admin ? 
                        <>
                            <h2>Mitarbeiterdatenbank</h2>
                            <div className="row">
                                <form className="inputField addInputField">
                                    <input type="textfield" className="inputTitle" name="input_title" placeholder="Feldtitel" value={this.state.inputField.input_title} onChange={this.handleChange}/>
                                    <select className="inline" name="input_type" value={this.state.inputField.input_type} onChange={this.handleChange}>
                                        <option value="Textfeld">Textfeld</option>
                                        <option value="Datei">Datei</option>
                                    </select>
                                    <button onClick={this.addInputField}><IoAdd/></button>
                                </form>
                            </div>
                            {this.state.inputFieldList}
                        </>
                        : <></>}
                </div>
            </div>
        );
    }

}