import React, { Component } from "react";
import Select from 'react-select';

const customStyles = {
    container: () => ({
      minWidth: "10rem",
      fontSize: "90%"
    }),
    indicatorsContainer: () => ({
        padding: "0"
      }),
    dropdownIndicator: () => ({
        padding: "0",
        color: "#cccccc"
    }),
    control: (provided) => ({
        ...provided,
        minHeight: "0",
        cursor: "pointer"
      }),
    input: () => ({
        color: "#1D1E23",
        padding: "0.2rem 0.2rem",
        outline: "none !important"
      }),
    menu: () => ({
        minWidth: "10rem",
        borderBottom: '1px dotted pink',
        position: "absolute",
        background: "#ffffff",
        color: "#000000",
        padding: "0"
    }),
    menuList: () => ({
        padding: "0",
        maxHeight: "15rem",
        overflowY: "scroll",
      }),
  }


class ProjectSelection extends Component{

    constructor(props){
        super(props);

        this.state = {
            currentProject : props.currentProject
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(option){
        let value = option.value;
        let foundProject;
        if(value !== -1){
            this.props.projectList.forEach(project => {
                if(project.P_ID === value){
                    foundProject = project;
                }
            });
        }else{
            foundProject = {P_ID: -1, P_Name: "", P_Color: ""};
        }

        this.setState({currentProject: foundProject});
        this.props.callback(foundProject);
    }  

    

    render(){

        let projectList = [{label: "Kein Projekt", value : -1}];
        
        const options = projectList.concat(this.props.projectList.map(project => ({label: project.P_Name, value: project.P_ID})));

        return (
            <div className="row horizontal projectSelection" onClick={(event) => {event.preventDefault(); event.stopPropagation();}}>
                <label>Projekt:</label>
                <Select isSearchable={true}  options={options} styles={customStyles} onChange={this.handleChange} defaultValue={options[0]} theme={theme => ({...theme,borderRadius: 0,colors: {...theme.colors,primary25: '#cccccc',primary50: "#cccccc",primary: '#2F2F38',}})}/>
            </div>
        )
    }
}


export default ProjectSelection; 