import { Stack } from "@mui/material";

export default function EqualStack(props) {
  return (
    <Stack
      {...props}
      sx={{
        "&":{
          flexWrap: "wrap"
        },
        
        "&>*": {
          flexBasis: 0,
          flexGrow: 1,
        },
        ...props.sx,
      }}
    />
  );
}
