import React from "react";
import {FaCalendarAlt,FaUserFriends,FaFolder,FaSlidersH, FaUserAlt} from "react-icons/fa";
import {BiCalendarEdit} from "react-icons/bi";

class MenuItem extends React.Component {

    render() {
        let menuIcon;
        switch(this.props.title) {
            case "Planung":
                menuIcon = <BiCalendarEdit/>;
                break;
            case "Woche":
                menuIcon = <FaCalendarAlt/>;
                break;
            case "Mitarbeiter":
                menuIcon = <FaUserFriends/>;
                break;
            case "Projekte":
                menuIcon = <FaFolder/>;
                break;
            case "Profil":
                menuIcon = <FaUserAlt/>;
                break;
            case "Einstellungen":
                menuIcon = <FaSlidersH/>;
                break;
            default:
          }
        return(
            <li id={this.props.title} className={this.props.isActive ? "menuItem active" : "menuItem"} onClick={() => {this.props.setSelected(this.props.title); this.props.toggleMenu()}}>
                <div className="menuIcon">{menuIcon}</div>
                <div className="menuText">{this.props.title}</div>
            </li>
        );
    }
}

export default MenuItem;