import React from "react";
import "./_ContextMenu.scss";

class ContextMenu extends React.Component{
    constructor(){
        super();
        this.triggerMenu = this.triggerMenu.bind(this);
        this.detachMenu = this.detachMenu.bind(this);

        this.state = {
            visible : false,
            x : 0,
            y : 0
        }
    }

    triggerMenu(event){
        event.preventDefault();

        const clickX = event.clientX;
        const clickY = event.clientY;

        const viewportOffset = event.target.getBoundingClientRect();
        const targetX = viewportOffset.left;
        const targetY = viewportOffset.top;
        
        this.setState({ visible: true, x: clickX - targetX, y: clickY - targetY });
        
        let self = this;

        setTimeout(function(){
            document.addEventListener("click", self.detachMenu);
            document.addEventListener("contextmenu", self.detachMenu);
        }, 200);
    }

    detachMenu(event){
        document.removeEventListener("click", this.detachMenu);
        document.removeEventListener("contextmenu", this.detachMenu);
        this.setState({
            visible: false
        })
        event.preventDefault();
    }

    render(){

        return (
            <div onContextMenu={this.triggerMenu} style={{position: "relative"}}>
                {this.state.visible ? 
                    <div className="contextMenu" style={{left: this.state.x + "px", top: this.state.y + "px"}}>
                        <ul>
                            {this.props.menuItems.map((item, index) => {
                                return <li key={index} onClick={() => this.props.callback(index)}>{item}</li>
                            })}
                        </ul>
                    </div> : ""}
                {this.props.children}
            </div>
        );
    }
}

export default ContextMenu;