import React, { Component } from "react";

import WeekHead from "./WeekHead";
import moment from 'moment';
import styles from "./_MemberTable.module.scss";

class MemberHead extends Component{


    render(){
        let weekHeads = [];
        for(let i = 0; i < this.props.numberOfWeeks; i++){
            let firstDay = moment(this.props.firstDay).add(i * 7, 'days');
            weekHeads.push(<WeekHead key={firstDay.toDate()} firstDay={firstDay.toDate()}/>);
        }

        return (
            <tr  className={styles.headerSecondRow}>
                {weekHeads}
            </tr>
        )
    }
}

export default MemberHead; 