import {
    Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { emitEvent } from "../index.js";

const AddTeacherDialog = ({ open, members, setOpen }) => {
  const [member, setMember] = useState({
    M_ID: -1,
    M_FirstName: "",
    M_LastName: "",
    M_Mail: "",
    M_Super: 0,
    M_Admin: 0,
    M_EditSelf: 0,
    M_EditSubs: 0,
    M_LeaveDate: null,
  });

  const handleSubmit = () => {
    emitEvent("updateMember", member);
    setOpen(false);
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Mitarbeiter hinzufügen</DialogTitle>
      <DialogContent sx={{ width: "400px", maxWidth: "100%" }}>
        <Stack direction={"column"} gap={2} pt={0.5}>
          <TextField
            value={member.M_FirstName}
            label={"Vorname"}
            onChange={(e) =>
              setMember((m) => ({ ...m, M_FirstName: e.target.value }))
            }
            fullWidth
          />
          <TextField
            value={member.M_LastName}
            label={"Nachname"}
            onChange={(e) =>
              setMember((m) => ({ ...m, M_LastName: e.target.value }))
            }
            fullWidth
          />
          <TextField
            value={member.M_Mail}
            label={"E-Mail"}
            onChange={(e) =>
              setMember((m) => ({ ...m, M_Mail: e.target.value }))
            }
            fullWidth
          />
          <FormControl fullWidth>
            <InputLabel htmlFor="M_Super">Projektleiter</InputLabel>
            {
              <Select
                id={"M_Super"}
                name="M_Super"
                label={"Projektleiter"}
                value={member.M_Super}
                onChange={(e) => {
                  setMember((m) => {
                    handleSubmit({ ...m, M_Super: e.target.value });
                    return { ...m, M_Super: e.target.value };
                  });
                }}
              >
                <MenuItem key={0} value={0}>
                  Kein Projektleiter
                </MenuItem>
                {members
                  .filter((m) => m.M_ID !== member.M_ID)
                  .map((member) => {
                    return (
                      <MenuItem key={member.M_ID} value={member.M_ID}>
                        {member.M_FirstName + " " + member.M_LastName}
                      </MenuItem>
                    );
                  })}
              </Select>
            }
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction={"row"} justifyContent={'flex-end'} gap={2}>
          <Button variant={"outlined"} onClick={() => setOpen(false)}>
            Abbrechen
          </Button>
          <Button variant={"contained"} onClick={() => handleSubmit()} disabled={member.M_FirstName.trim() === '' || member.M_LastName.trim() === '' || member.M_Mail.trim() === ''}>
            Speichern
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default AddTeacherDialog;
