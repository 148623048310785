import React from "react";

class Tab extends React.Component {

    render() {
        if(this.props.isSelected) {
            return(
                <main>
                    {this.props.children}
                </main>
            );
        }
        return null;
    }
}

export default Tab;