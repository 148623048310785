import React, { Component } from "react";
import moment from 'moment';
import styles from "./_MemberTable.module.scss";

class CalendarHead extends Component{

    render(){
        let weekHeads = [];
        for(let i = 0; i < this.props.numberOfWeeks; i++){
            let firstDay = moment(this.props.firstDay).add(i * 7, 'days');
            let isCurrentWeek = (firstDay.isSame(moment(), "isoweek") && moment().isoWeekday() < 5) || (firstDay.isSame(moment().add(1, "week"), "isoweek") && moment().isoWeekday() >= 5);

            weekHeads.push(<th ref={isCurrentWeek ? this.props.setWeekRef : null} colSpan="8" key={firstDay.isoWeek()}>Kalenderwoche {firstDay.isoWeek()}</th>)
        
        }

        let rowHeadText = "Mitarbeiter";
        if(this.props.screenSize.width === "small"){
            rowHeadText = "";
        }

        return (
            <tr className={styles.headerFirstRow}>
                <th rowSpan="2" ref={this.props.setRowHeadRef} className={styles.rowHead}>{rowHeadText}</th>
                {weekHeads}
            </tr>
        )
    }
}


export default CalendarHead;