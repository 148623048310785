import React, { Component } from "react";
import WeekRow from "./WeekRow.js";
import moment from "moment";
import styles from "./_MemberTable.module.scss";

class MemberRow extends Component {
  render() {
    let weekRows = [];

    //Are only allowed to edit if Admin, can edit submembers or themselves
    let isAllowedToEdit =
      this.props.currentUser.M_Admin ||
      (this.props.member.M_ID !== this.props.currentUser.M_ID &&
        this.props.currentUser.M_EditSubs) ||
      (this.props.member.M_ID === this.props.currentUser.M_ID &&
        this.props.currentUser.M_EditSelf);
    if (this.props.dates && this.props.dates.length) {
      for (let i = 0; i < this.props.numberOfWeeks; i++) {
        let firstDay = moment(this.props.firstDay).add(i * 7, "days");

        let sortedDates = this.props.dates.slice(i * 8, (i + 1) * 8);
        if (sortedDates.length)
          weekRows.push(
            <WeekRow
              allowedToEdit={isAllowedToEdit}
              selectedList={this.props.selectedList}
              memberId={this.props.member.M_ID}
              key={`${sortedDates[0].T_Date}-${sortedDates[0].M_ID}-${sortedDates[0].T_WeekInfo}`}
              firstDay={firstDay.toDate()}
              dates={sortedDates}
              projects={this.props.projects}
              darker={this.props.darker}
              connected={this.props.connected}
              addDate={this.props.addDate}
              changeSelection={this.props.changeSelection}
              pasteProjectToSelected={this.props.pasteProjectToSelected}
              deleteSelected={this.props.deleteSelected}
              openDialogue={this.props.openDialogue}
              changeSelectionBox={this.props.changeSelectionBox}
              copySelected={this.props.copySelected}
              pasteFromClipboard={this.props.pasteFromClipboard}
            />
          );
      }
    }

    let rowHeadText =
      this.props.member.M_FirstName.charAt(0) +
      ". " +
      this.props.member.M_LastName;
    if (this.props.screenSize.width === "small") {
      rowHeadText =
        this.props.member.M_FirstName.charAt(0) +
        this.props.member.M_LastName.charAt(0);
    }

    return (
      <tr>
        <th className={styles.rowHead}>{rowHeadText}</th>
        {weekRows}
      </tr>
    );
  }
}

export default MemberRow;
