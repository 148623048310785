import React, { useState } from "react";
import "./_MemberList.scss";
import { BsArrowReturnRight } from "react-icons/bs";
import { HiOutlineMenu } from "react-icons/hi";
import { GrClose } from "react-icons/gr";
import { GrAdd } from "react-icons/gr";
import { emitEvent } from "../index.js";
import AddTeacherDialog from "./AddTeacherDialog";

const MemberList = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDelete = (member) => {
    if (
      window.confirm(
        member.M_FirstName + " " + member.M_LastName + " wirklich löschen?"
      )
    ) {
      emitEvent("deleteMember", member);

      props.membersChanged();
    }
  };

  const dragStart = (e, member) => {
    e.dataTransfer.setData("movingMemberID", member.M_ID);
    e.dataTransfer.setData("movingMemberLastPosition", member.M_Position);
  };

  const dragOver = (e) => {
    e.preventDefault();
    e.currentTarget.classList.add("dragOver");
  };

  const dragLeave = (e) => {
    e.preventDefault();
    e.currentTarget.classList.remove("dragOver");
  };

  const drop = (e, member, dropCase) => {
    e.preventDefault();
    e.currentTarget.classList.remove("dragOver");
    if (e.dataTransfer.getData("movingMemberID") !== undefined) {
      let movingMemberNewPosition = member.M_Position.split(",");
      let newM_Super = member.M_Super;

      if (dropCase === "sub") {
        movingMemberNewPosition.push("1");
        newM_Super = member.M_ID;
      } else if (dropCase === "last") {
        movingMemberNewPosition = movingMemberNewPosition.slice(0, 1);
        movingMemberNewPosition[0]++;
        newM_Super = 0;
      }

      emitEvent(
        "updateMemberPosition",
        e.dataTransfer.getData("movingMemberID"),
        e.dataTransfer.getData("movingMemberLastPosition"),
        movingMemberNewPosition.join(),
        newM_Super
      );
    }
  };

  let memberListItems = props.members.map((member) => {
    let style = {
      marginLeft:
        Math.max(member.M_Position.split(",").length - 1, 0) * 4 + "rem",
    };

    return (
      <React.Fragment key={member.M_ID}>
        <li
          className="memberListDropPlaceholder"
          onDragOver={dragOver}
          onDrop={(e) => drop(e, member, "before")}
          onDragLeave={dragLeave}
          style={style}
        ></li>
        <li className="memberListItem" style={style}>
          {member.M_Super !== 0 ? (
            <div className="memberListSubIcon">
              <BsArrowReturnRight />
            </div>
          ) : (
            ""
          )}
          <div
            className="memberListRectangle"
            draggable={true}
            onDragStart={(e) => dragStart(e, member)}
            onDragOver={dragOver}
            onDrop={(e) => drop(e, member, "sub")}
            onDragLeave={dragLeave}
          >
            <div className="memberListIcon">
              <HiOutlineMenu />
            </div>
            <div
              className="memberText memberListName"
              onClick={() =>
                props.setSelected("MemberDetailsPage", member)
              }
            >
              {member.M_FirstName + " " + member.M_LastName}
            </div>
            <div
              className="memberListIcon memberListRemove"
              onClick={() => handleDelete(member)}
            >
              <GrClose />
            </div>
          </div>
        </li>
      </React.Fragment>
    );
  });

  let lastMember = props.members[props.members.length - 1];

  return (
    <div className="tab">
      <div className="content medium">
        <ul className="memberList">
          {memberListItems}
          <li
            key={"lastPlaceholder"}
            className="memberListDropPlaceholder"
            onDragOver={dragOver}
            onDrop={(e) => drop(e, lastMember, "last")}
            onDragLeave={dragLeave}
          ></li>
          <li
            key={"addMember"}
            className="memberListItem addMember"
            onClick={() => setDialogOpen(true)}
          >
            <div className="memberListIcon memberListAdd">
              <GrAdd />
            </div>
          </li>
        </ul>
      </div>
      <AddTeacherDialog open={dialogOpen} setOpen = {setDialogOpen} members={props.members} />
    </div>
  );
};

export default MemberList;
