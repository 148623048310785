import React, { Component } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { BiRefresh } from "react-icons/bi";
import "./_MemberTableSelector.scss";
import ConnectionCheck from "./ConnectionCheck.js";
import ProjectSelection from "./ProjectSelection";

const months = [
  "Januar",
  "Februar",
  "März",
  "April",
  "Mai",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "Dezember",
];

class MemberTableSelector extends Component {
  render() {
    return (
      <div className="memberTableSelector">
        <div className="projectSelectionWrapper">
          <ProjectSelection
            currentProject={{ P_Name: "Kein Projekt", P_ID: -1, P_Color: "" }}
            callback={this.props.changeSelectedProject}
            projectList={this.props.projects}
          />
        </div>

        <div className="selector">
          <div className="selectorLeft">
            <IoIosArrowBack
              onClick={() => this.props.changeDate("years", -1)}
            />
          </div>
          <div className="selectorText">{this.props.date.year()}</div>
          <div className="selectorRight">
            <IoIosArrowForward
              onClick={() => this.props.changeDate("years", 1)}
            />
          </div>
        </div>
        <div className="selector">
          <div className="selectorLeft">
            <IoIosArrowBack
              onClick={() => this.props.changeDate("months", -1)}
            />
          </div>
          <div className="selectorText">{months[this.props.date.month()]}</div>
          <div className="selectorRight">
            <IoIosArrowForward
              onClick={() => this.props.changeDate("months", 1)}
            />
          </div>
        </div>

        <div className="serverStatus">
          <ConnectionCheck connected={this.props.connected} />
          <div className="refresh">
            <BiRefresh onClick={this.props.refreshServer} />
          </div>
        </div>
      </div>
    );
  }
}

export default MemberTableSelector;
