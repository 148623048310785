import React, { Component } from "react";
import MemberTable from "./MemberTableEditor.js";
import MemberList from "./MemberList.js";
import TabNav from "./TabNav.js";
import Tab from "./Tab.js";
import SettingsPage from "./SettingsPage.js";
import "./_App.scss";
import "./_Tab.scss";
import "./_InputFields.scss";
import {
  setListener,
  emitEvent,
  isConnectedToServer,
  setConnectionListener,
} from "../index.js";
import EditProject from "./EditProject.js";
import ProjectList from "./ProjectList.js";
import LoginForm from "./LoginForm.js";
import Cookies from "universal-cookie";
import MemberDetailsPage from "./MemberDetailsPage.js";
import MemberWeekView from "./MemberWeekView/MemberWeekView.jsx";
import { createTheme, ThemeProvider } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import ViewDateHours from "./ViewDateHours.jsx";

class App extends Component {
  constructor() {
    super();

    this.state = {
      loggedIn: false,
      currentUser: {},
      loginFailed: false,
      selected: "Login",
      selectData: {},
      members: [],
      projects: [],
      connected: isConnectedToServer(),
      dialogue: <></>,
    };

    this.theme = createTheme({
      palette: {
        primary: {
          main: "#2F2F38",
          light: "#454a69",
          dark: "#2d3141",
          contrastText: "#fff",
        },
      },
    });

    this.setSelected = this.setSelected.bind(this);
    this.refreshServer = this.refreshServer.bind(this);
    this.membersChanged = this.membersChanged.bind(this);
    this.projectsChanged = this.projectsChanged.bind(this);
    this.logout = this.logout.bind(this);
    this.setCookie = this.setCookie.bind(this);
    this.getCookie = this.getCookie.bind(this);
    this.cookies = new Cookies();

    setListener(
      "login",
      function (result, currentUser) {
        if (result === true) {
          this.membersChanged();
          this.projectsChanged();
          this.setSelected(currentUser.M_Admin ? "Planung" : "Woche");
          this.setState({
            loggedIn: true,
            currentUser: currentUser,
          });
          this.setCookie("token", currentUser.token, { path: "/" });
        } else {
          console.log("Login failed");
          this.removeCookie("token", { path: "/" });
          this.setState({
            loggedIn: false,
            loginFailed: true,
          });
        }
      }.bind(this)
    );

    setConnectionListener(
      function (result) {
        this.setState({
          connected: result,
        });
        if (result) {
          if (
            this.getCookie("token") &&
            this.getCookie("token") !== undefined
          ) {
            emitEvent("loginWithToken", this.getCookie("token"));
          }
        } else {
          if (typeof window.electron == "undefined") {
            this.refreshServer();
          }
        }
      }.bind(this)
    );

    setListener(
      "passwordReset",
      function (id) {
        if (id === this.state.currentUser.M_ID) {
          this.removeCookie("token");
          this.refreshServer();
        }
      }.bind(this)
    );

    setListener(
      "selectMembers",
      function (results) {
        if (results.length !== 0) {
          this.setState({
            members: results,
          });
        }
      }.bind(this)
    );

    setListener(
      "selectProjects",
      function (results) {
        this.setState({
          projects: results,
        });
      }.bind(this)
    );

    setListener(
      "membersUpdated",
      function () {
        this.membersChanged();
      }.bind(this)
    );

    setListener(
      "projectsUpdated",
      function () {
        this.projectsChanged();
      }.bind(this)
    );
  }

  logout() {
    this.removeCookie("token");
    this.refreshServer();
  }

  setCookie(cookieName, value, options) {
    if (options) {
      this.cookies.set(cookieName, value, options);
    } else {
      this.cookies.set(cookieName, value, { path: "/" });
    }
  }

  getCookie(cookieName, options) {
    return this.cookies.get(cookieName, options);
  }

  removeCookie(cookieName, options) {
    return this.cookies.remove(cookieName, options);
  }

  membersChanged() {
    emitEvent("selectMembers");
  }

  projectsChanged() {
    emitEvent("selectProjects");
  }

  setSelected(tab, data) {
    this.setState({
      selected: tab,
      selectData: data,
    });
  }

  refreshServer() {
    window.location.reload();
  }

  render() {
    let dialogue = this.state.dialogue;
    var visibleTabs = ["Woche", "Profil", "Einstellungen"];
    if (this.state.currentUser.M_Admin) {
      visibleTabs = [
        "Planung",
        "Woche",
        "Mitarbeiter",
        "Projekte",
        "Profil",
        "Einstellungen",
      ];
    } else if (
      this.state.currentUser.M_EditSubs ||
      this.state.currentUser.M_EditSelf
    ) {
      visibleTabs = ["Woche", "Planung", "Profil", "Einstellungen"];
    }

    return (
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"de"}>
        <ThemeProvider theme={this.theme}>
          <div className="app">
            {dialogue}
            <Tab isSelected={this.state.selected === "Login"}>
              <LoginForm
                setSelected={this.setSelected}
                failed={this.state.loginFailed}
              />
            </Tab>
            {this.state.selected !== "Login" ? (
              <TabNav
                tabs={visibleTabs}
                selected={this.state.selected}
                setSelected={this.setSelected}
              />
            ) : (
              ""
            )}
            <Tab isSelected={this.state.selected === "Planung"}>
              <MemberTable
                ref={this.memberTable}
                currentUser={this.state.currentUser}
                members={this.state.members}
                projects={this.state.projects}
                connected={this.state.connected}
                refreshServer={this.refreshServer}
                loggedIn={this.state.loggedIn}
              />
            </Tab>
            <Tab isSelected={this.state.selected === "Woche"}>
              <MemberWeekView
                currentUser={this.state.currentUser}
                loggedIn={this.state.loggedIn}
                projects={this.state.projects}
              />
            </Tab>
            <Tab isSelected={this.state.selected === "Mitarbeiter"}>
              <MemberList
                members={this.state.members}
                setSelected={this.setSelected}
                membersChanged={this.membersChanged}
              />
            </Tab>
            <Tab isSelected={this.state.selected === "Projekte"}>
              <ProjectList
                projects={this.state.projects}
                setSelected={this.setSelected}
                projectsChanged={this.projectsChanged}
              />
            </Tab>
            <Tab isSelected={this.state.selected === "Einstellungen"}>
              <SettingsPage
                currentUser={this.state.currentUser}
                logout={this.logout}
              />
            </Tab>
            <Tab isSelected={this.state.selected === "Profil"}>
              <MemberDetailsPage
                setSelected={this.setSelected}
                currentUser={this.state.currentUser}
                editMode={true}
                member={this.state.currentUser}
                members={this.state.members}
                membersChanged={this.membersChanged}
              />
            </Tab>
            <Tab isSelected={this.state.selected === "MemberDetailsPage"}>
              <MemberDetailsPage
                setSelected={this.setSelected}
                member={this.state.selectData}
                editMode={false}
                currentUser={this.state.currentUser}
                members={this.state.members}
                membersChanged={this.membersChanged}
              />
            </Tab>
            <Tab isSelected={this.state.selected === "EditProject"}>
              <EditProject
                setSelected={this.setSelected}
                project={this.state.selectData}
                projectsChanged={this.projectsChanged}
              />
            </Tab>
            <Tab isSelected={this.state.selected === "ViewDateHours"}>
              <ViewDateHours
                setSelected={this.setSelected}
                data={this.state.selectData}
              />
            </Tab>
          </div>
        </ThemeProvider>
      </LocalizationProvider>
    );
  }
}

export default App;
