import React, { Component } from "react";
import { SketchPicker } from 'react-color';
import {shadeColor, emitEvent} from "../index.js";
import "./_EditProject.scss";


class EditProject extends Component{

    constructor(props){
        super(props);
        if(props.project == null){
            this.state = {
                project: {
                    P_ID: -1,
                    P_Name : "",
                    P_Color : "#cccccc"
                }
            }
        }else{
            this.state = {
                project : props.project
            }
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleColorChange = this.handleColorChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e){
        e.preventDefault();
        emitEvent("updateProject", this.state.project);
        this.props.projectsChanged();
        this.props.setSelected("Projekte");
    }

    handleChange(event){
        this.setState(prevState => {
            let project = prevState.project;

            project[event.target.name] = event.target.value

            return (
                {
                    project: project
                }
            )
        })
    }
    handleColorChange(color){
        this.setState(prevState => {
            let project = prevState.project;

            project.P_Color = color.hex

            return (
                {
                    project: project
                }
            )
        })
    }

    render(){
        return(
            <div className="tab">
                <form className="content medium">
                    <h2>Projekt verwalten</h2>
                    <div className="row">
                        <div className="inputText">Name:</div>
                        <input type="textfield" name="P_Name" value={this.state.project.P_Name} onChange={this.handleChange}></input>
                    </div>
                    <div className="row">
                        <div className="inputText">Farbe:</div>
                        <div className="colorSection">
                            <SketchPicker color={ this.state.project.P_Color }
                            onChange={ this.handleColorChange }/>
                            <div className="colorPreview">
                                <div className="normalColorPreview" style={{background: this.state.project.P_Color}}>
                                    <span className="textColorPreview">Beispieltext</span>
                                </div>
                                <div className="shadedColorPreview" style={{background: shadeColor(this.state.project.P_Color, -16)}}>
                                    <span className="textColorPreview">Beispieltext</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row horizontal">
                        <button onClick={() => this.props.setSelected("Projekte")}>Zurück</button>
                        <button onClick={this.handleSubmit}>Speichern</button>
                    </div> 
                </form>
            </div>
        )
    }

}

export default EditProject;