import React, { Component } from "react";
import { emitEvent, shadeColor } from "../index.js";
import ContextMenu from "./ContextMenu.js";
import styles from "./_MemberTable.module.scss";

class CellEntry extends Component {
  constructor(props) {
    super(props);

    this.cellEntryHtml = React.createRef();
    this.mouseEventElement = React.createRef();

    this.state = {
      date: props.date,
      editMode: false,
      isAllowedToEdit: props.allowedToEdit && props.connected,
    };

    this.handleChange = this.handleChange.bind(this);
    this.setDateProject = this.setDateProject.bind(this);
    this.handleContextMenuReturn = this.handleContextMenuReturn.bind(this);
    this.copyCellEntry = this.copyCellEntry.bind(this);
    this.pasteCellEntry = this.pasteCellEntry.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.toggleEditMode = this.toggleEditMode.bind(this);
    this.getCoordinates = this.getCoordinates.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.date !== prevProps.date) {
      this.setState({
        date: this.props.date,
      });
    }
    if (this.props.connected !== prevProps.connected) {
      if (this.props.connected) {
        this.setState({
          isAllowedToEdit: this.props.allowedToEdit && this.props.connected,
        });
      } else {
        this.setState({
          isAllowedToEdit: this.props.allowedToEdit && this.props.connected,
        });
      }
    }
  }

  handleChange(event) {
    if (this.props.connected) {
      this.setState((prevState) => {
        let date = prevState.date;

        date.T_Content = event.target.value;

        emitEvent("updateCellEntry", date);

        return { date: date };
      });
    }
  }

  setDateProject(project) {
    this.setState((prevState) => {
      let date = prevState.date;

      date.P_ID = project.P_ID;

      if (this.state.date.T_Content === "" && project.P_ID !== -1) {
        date.T_Content = project.P_Name;
      }

      emitEvent("updateCellEntry", date);

      return { date: date };
    });
  }

  handleContextMenuReturn(index) {
    switch (index) {
      case 0:
        this.copyCellEntry();
        break;
      case 1:
        this.pasteCellEntry();
        break;
      case 2:
        this.props.deleteSelected();
        break;
      case 3:
        this.props.pasteProjectToSelected();
        break;
      case 4:
        emitEvent("sendReminderMail", this.state.date);
        break;
      default:
        break;
    }
  }

  copyCellEntry() {
    this.props.copySelected();
  }

  pasteCellEntry() {
    this.props.pasteFromClipboard();
  }

  toggleEditMode() {
    if (this.props.allowedToEdit) {
      if (!this.state.editMode) {
        this.props.changeSelection(
          this.state.date,
          true,
          this.getCoordinates()
        );
      }
      this.setState((prevState) => {
        let newState = prevState;

        newState.editMode = !prevState.editMode;

        return newState;
      });
    }
  }

  handleMouseDown(e) {
    let selected = false;
    for (var i = 0; i < this.props.selectedList.length; i++) {
      if (this.props.selectedList[i][0] === this.state.date.rowIndex && this.props.selectedList[i][1] === this.state.date.columnIndex) {
        selected = true;
      }
    }

    if (e.buttons === 1 || e.buttons === 3 || !selected) {
      if (!e.shiftKey) {
        this.props.changeSelection(this.state.date, 1, this.getCoordinates());
      } else {
        this.props.changeSelection(this.state.date, 2, this.getCoordinates());
      }
    }
  }
  handleMouseOver(e) {
    if (e.buttons === 1 || e.buttons === 3) {
      this.props.changeSelection(this.state.date, 3, this.getCoordinates());
    }
  }

  getCoordinates() {
    return {
      left: this.cellEntryHtml.current.offsetLeft,
      right:
        this.cellEntryHtml.current.offsetWidth +
        this.cellEntryHtml.current.offsetLeft,
      top: this.cellEntryHtml.current.offsetTop,
      bottom:
        this.cellEntryHtml.current.offsetHeight +
        this.cellEntryHtml.current.offsetTop,
    };
  }

  render() {
    let style = {};

    this.props.projects.forEach((project) => {
      if (
        this.state.date.P_ID !== -1 &&
        project.P_ID === this.state.date.P_ID
      ) {
        const color = project.P_Color;
        const shadedColor = shadeColor(project.P_Color, -16);
        const borderColor = shadeColor(project.P_Color, -30);

        if (this.props.darker) {
          style = {
            background: shadedColor,
            borderRight: "solid " + borderColor + " 1px",
            borderBottom: "solid " + borderColor + " 1px",
          };
        } else {
          style = {
            background: color,
            borderRight: "solid " + borderColor + " 1px",
            borderBottom: "solid " + borderColor + " 1px",
          };
        }
      }
    });

    if (this.state.editMode) {
      return (
        <>
          <td
            ref={this.cellEntryHtml}
            className={styles.cellEntry + " " + (this.props.special ? styles.weekSpecial : "")}
            style={style}
          >
            <textarea
              autoFocus
              className={styles.entry}
              value={this.state.date.T_Content}
              onChange={this.handleChange}
              onBlur={this.toggleEditMode}
            />
          </td>
        </>
      );
    } else {
      return (
        <>
          <td
            ref={this.cellEntryHtml}
            className={styles.cellEntry + " " + (this.props.special ? styles.weekSpecial : "")}
            style={style}
            onCopy={this.copyCellEntry}
          >
            <ContextMenu
              changeSelection={this.changeSelectionOnContextMenu}
              callback={this.handleContextMenuReturn}
              menuItems={[
                "Kopieren",
                "Einfügen",
                "Löschen",
                "Projekt einfügen",
                "Erinnerung senden",
              ]}
            >
              <div
                className={styles.entry}
                onMouseDown={this.handleMouseDown}
                onMouseOver={this.handleMouseOver}
                onDoubleClick={this.toggleEditMode}
                ref={this.mouseEventElement}
              >
                {this.state.date.T_Content}
              </div>
            </ContextMenu>
          </td>
        </>
      );
    }
  }
}

export default CellEntry;
