import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { emitEvent, setListener, unsetListener } from "..";
import {
  calculateWorkingHours,
  dayTypeToMoney,
  dayTypeToText,
} from "./MemberWeekView/EditHoursDialog";
import localization from "moment/locale/de";
import styles from "./ViewDateHours.module.scss";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

export default function ViewDateHours(props) {
  const [dateHoursList, setDateHoursList] = useState([]);
  const [currentStartDate, setCurrentStartDate] = useState(
    moment().locale("de", localization).subtract(1, "month").startOf("month")
  );
  let printRef = useRef();

  useEffect(() => {
    if (!(props.data && props.data.member && currentStartDate)) return;

    const currentEndDate = currentStartDate.clone().endOf("month");

    const parseDateHoursList = (serverDateHoursList) => {
      let newDateHoursList = new Array(currentStartDate.daysInMonth());

      for (let i = 0; i < newDateHoursList.length; i++) {
        newDateHoursList[i] = { date: i + 1 };
      }

      for (let dateHours of serverDateHoursList) {
        let dateHoursEntry = {
          H_Notes: dateHours.H_Notes,
          H_StartTime: moment(dateHours.H_StartTime, "HH:mm:ss"),
          H_EndTime: moment(dateHours.H_EndTime, "HH:mm:ss"),
          H_BreakDuration: moment(dateHours.H_BreakDuration, "HH:mm:ss"),
        };
        try {
          dateHoursEntry.H_Props = JSON.parse(dateHours.H_Props);
        } catch {}

        newDateHoursList[moment(dateHours.H_Date).date() - 1] = {
          ...newDateHoursList[moment(dateHours.H_Date).date() - 1],
          ...dateHoursEntry,
        };
      }
      setDateHoursList(newDateHoursList);
    };

    setListener("selectDateHours", parseDateHoursList);

    emitEvent(
      "selectDateHours",
      currentStartDate.format("YYYY-MM-DD"),
      currentEndDate.format("YYYY-MM-DD"),
      props.data.member.M_ID
    );

    return () => {
      unsetListener("selectDateHours");
    };
  }, [props.data, currentStartDate]);

  if (!(props.data && props.data.member && currentStartDate)) return <></>;

  let totalWorkingHours = 0;
  let totalMoney = 0;

  const handleDownloadPdf = async () => {
    const element = printRef.current;

    element.style.width = element.offsetHeight * 0.7 + "px";
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/png");
    element.style.width = "auto";

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);

    let pdfWidth = 0;
    let pdfHeight = 0;
    if (imgProperties.height / imgProperties.width < 1.414) {
      pdfWidth = pdf.internal.pageSize.getWidth();
      pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    } else {
      pdfHeight = pdf.internal.pageSize.getHeight();
      pdfWidth = (imgProperties.width * pdfHeight) / imgProperties.height;
    }

    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save(
      `${props.data.member.M_FirstName}-${
        props.data.member.M_LastName
      }-${currentStartDate.format("YYYY")}-${currentStartDate.format("MMMM")}`
    );
  };

  return (
    <div className={styles.viewDateHoursWrapper} id="pdf" ref={printRef}>
      <header className={styles.header} style={{ maxWidth: "100%" }}>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <h1>{`Arbeitszeitnachweis ${props.data.member.M_FirstName} ${props.data.member.M_LastName}`}</h1>
          <IconButton color="primary" onClick={handleDownloadPdf}>
            <SaveAltIcon />
          </IconButton>
        </Stack>
        <Stack spacing={2}>
          <FormControl fullWidth>
            <InputLabel id="month-select-label">Monat</InputLabel>
            <Select
              labelId="month-select-label"
              id="month-select"
              value={currentStartDate.month()}
              label="Monat"
              onChange={(e) => {
                setCurrentStartDate((data) =>
                  moment(data).month(e.target.value)
                );
              }}
            >
              {[...Array(12).keys()].map((month) => (
                <MenuItem value={month} key={month}>
                  {moment()
                    .locale("de", localization)
                    .month(month)
                    .format("MMMM")}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="month-select-label">Jahr</InputLabel>
            <Select
              labelId="month-select-label"
              id="month-select"
              value={currentStartDate.year()}
              label="Jahr"
              onChange={(e) => {
                setCurrentStartDate((data) =>
                  moment(data).year(e.target.value)
                );
              }}
            >
              {Array.from(
                { length: moment().year() - 2020 + 1 },
                (value, index) => 2020 + index
              ).map((year) => (
                <MenuItem value={year} key={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </header>
      <TableContainer px={{ maxWidth: "30em", overflowX: "scroll" }}>
        <Table aria-label="date-hours-table" stickyHeader size={"small"}>
          <caption>
            geprüft am: ____________ Unterschrift Lohnbuchhaltung:
            ________________________
          </caption>
          <TableHead>
            <TableRow
              sx={{
                "& td, & th": { padding: "0.6em 1em" },
              }}
            >
              <TableCell align="left" width={30}>
                Tag
              </TableCell>
              <TableCell align="left" width={40}>
                Beginn
              </TableCell>
              <TableCell align="left" width={40}>
                Pause
              </TableCell>
              <TableCell align="left" width={40}>
                Ende
              </TableCell>
              <TableCell align="left" width={70}>
                Arbeitszeit
              </TableCell>
              <TableCell align="left" width={70}>
                Tagesart
              </TableCell>
              <TableCell align="left" width={70}>
                Ausl&ouml;se
              </TableCell>
              <TableCell align="left">Bemerkungen</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dateHoursList.map((row) => {
              console.log(row);
              let workTime = "";
              if (row.H_EndTime && row.H_StartTime) {
                let time = calculateWorkingHours(
                  row.H_StartTime,
                  row.H_EndTime,
                  row.H_BreakDuration
                );
                totalWorkingHours += time;
                workTime = time.toFixed(2) + " h";
              }
              let dayTypeMoney = "";
              if (row.H_Props && row.H_Props.dayType) {
                let money = dayTypeToMoney(row.H_Props.dayType);
                dayTypeMoney = money.toFixed(2) + " EUR";
                totalMoney += money;
              }
              return (
                <TableRow
                  key={row.date}
                  sx={{
                    "& td:first-of-type, & th:first-of-type": {
                      borderLeft: "1px solid rgba(224, 224, 224, 1)",
                    },
                    "& td, & th": {
                      padding: "0.6em 1em",
                      borderBottom: "1px solid rgba(224, 224, 224, 1)",
                      borderRight: "1px solid rgba(224, 224, 224, 1)",
                    },
                  }}
                >
                  <TableCell align="left" component="th" scope="row" width={30}>
                    {row.date}
                  </TableCell>
                  <TableCell align="left" width={40}>
                    {row.H_StartTime && row.H_StartTime.format("HH:mm")}
                  </TableCell>
                  <TableCell align="left" width={40}>
                    {row.H_BreakDuration && row.H_BreakDuration.format("HH:mm")}
                  </TableCell>
                  <TableCell align="left" width={40}>
                    {row.H_EndTime && row.H_EndTime.format("HH:mm")}
                  </TableCell>
                  <TableCell align="left" width={60}>
                    {workTime}
                  </TableCell>
                  <TableCell align="left" width={60}>
                    {row.H_Props && dayTypeToText(row.H_Props.dayType)}
                  </TableCell>
                  <TableCell align="left" width={70}>
                    {dayTypeMoney}
                  </TableCell>
                  <TableCell align="left">
                    {row.H_Notes && row.H_Notes}
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow
              key="end"
              sx={{
                "& td:first-of-type, & th:first-of-type": {
                  borderLeft: "1px solid rgba(224, 224, 224, 1)",
                },
                "& td, & th": {
                  padding: "0.6em 1em",
                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                },
              }}
            >
              <TableCell
                align="left"
                component="th"
                scope="row"
                width={30}
              ></TableCell>
              <TableCell align="left" width={40}></TableCell>
              <TableCell align="left" width={40}></TableCell>
              <TableCell align="left" width={40}>
                Gesamt:
              </TableCell>
              <TableCell align="left" width={60}>
                {totalWorkingHours.toFixed(2) + " h"}
              </TableCell>
              <TableCell align="left" width={60}></TableCell>
              <TableCell align="left" width={70}>
                {totalMoney.toFixed(2) + " EUR"}
              </TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
