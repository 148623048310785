import styles from "./_MemberWeekViewSelector.module.scss";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

export default function MemberWeekViewSelector(props) {
  return (
    <div className={styles.memberWeekViewSelector}>
      <div className={styles.selector}>
        <IoIosArrowBack onClick={() => props.changeDate("weeks", -1)} />
      </div>
      <span>Kalenderwoche {props.date.isoWeek()}</span>
      <div className={styles.selector}>
        <IoIosArrowForward onClick={() => props.changeDate("weeks", 1)} />
      </div>
    </div>
  );
}
