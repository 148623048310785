import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { emitEvent } from "../..";
import EqualStack from "../UI/EqualStack";

export default function EditHoursDialog({
  setDialogDateHours,
  dialogDateHours,
  updateDateHours,
}) {
  const [dateHours, setDateHours] = useState(dialogDateHours);
  const [valid, setValid] = useState(true);
  const [workHours, setWorkHours] = useState("00:00");
  const [dayTypeTextfield, setDayTypeTextfield] = useState("0.00 €");

  useEffect(() => {
    if(dialogDateHours?.foundProject && !dialogDateHours.serverLoaded){
      if(dialogDateHours.foundProject.P_Name === "Absetzer" || dialogDateHours.foundProject.P_Name === "Feiertag"){ //Special-S specific
        dialogDateHours.H_StartTime = moment().hour(0).minute(0)
        dialogDateHours.H_EndTime = moment().hour(0).minute(0)
        dialogDateHours.H_BreakDuration = moment().hour(0).minute(0)
        dialogDateHours.H_Notes = dialogDateHours.foundProject.P_Name
      }else if(dialogDateHours.foundProject.P_Name === "Krank"){
        dialogDateHours.H_StartTime = moment().hour(8).minute(0)
        dialogDateHours.H_EndTime = moment().hour(16).minute(0)
        dialogDateHours.H_BreakDuration = moment().hour(0).minute(0)
        dialogDateHours.H_Notes = dialogDateHours.foundProject.P_Name
      }
    }
    setDateHours(dialogDateHours);
  }, [dialogDateHours]);

  const submitForm = () => {
    if (valid) {
      emitEvent("updateDateHours", dateHours);
      updateDateHours(dateHours);

      setDialogDateHours(null);
    }
  };

  useEffect(() => {
    if (!dateHours) return;
    if (
      dateHours.H_StartTime &&
      dateHours.H_EndTime &&
      dateHours.H_BreakDuration &&
      dateHours.H_StartTime.isValid() &&
      dateHours.H_EndTime.isValid() &&
      dateHours.H_BreakDuration.isValid()
    ) {
      setValid(true);

      setWorkHours(
        calculateWorkingHours(
          dateHours.H_StartTime,
          dateHours.H_EndTime,
          dateHours.H_BreakDuration
        ).toFixed(2) + " h"
      );
    } else {
      setValid(false);
    }
    if (dateHours.H_Props && dateHours.H_Props.dayType) {
      setDayTypeTextfield(
        dayTypeToMoney(dateHours.H_Props.dayType).toFixed(2) + " €"
      );
    }
  }, [dateHours]);

  return (
    <Dialog open={dateHours !== null}>
      {dateHours !== null ? (
        <>
          <DialogTitle
            sx={{
              boxSizing: "border-box",
              maxWidth: "100%",
            }}
          >
            Arbeitsinfos {moment(dateHours.H_Date).format("DD.MM.YYYY")}
          </DialogTitle>
          <DialogContent sx={{
              boxSizing: "border-box",
              maxWidth: "100%",
            }}>
            <Stack spacing={2} marginTop={1}>
              <EqualStack direction="row" spacing={2}>
                <TimePicker
                  label="Beginn"
                  value={dateHours.H_StartTime}
                  ampm={false}
                  onChange={(newValue) => {
                    setDateHours((data) => ({
                      ...data,
                      H_StartTime: newValue,
                    }));
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
                <TimePicker
                  label="Ende"
                  value={dateHours.H_EndTime}
                  ampm={false}
                  onChange={(newValue) => {
                    setDateHours((data) => ({ ...data, H_EndTime: newValue }));
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </EqualStack>
              <EqualStack direction="row" spacing={2}>
                <TimePicker
                  label="Pausezeit"
                  value={dateHours.H_BreakDuration}
                  ampm={false}
                  maxTime={moment().hours(6)}
                  onChange={(newValue) => {
                    setDateHours((data) => ({
                      ...data,
                      H_BreakDuration: newValue,
                    }));
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
                <TextField
                  label="Arbeitszeit"
                  value={workHours}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </EqualStack>
              <EqualStack direction="row" spacing={2}>
                <FormControl fullWidth>
                  <InputLabel id="date-type-select-label">Tagesart</InputLabel>
                  <Select
                    labelId="date-type-select-label"
                    id="date-type-select"
                    value={dateHours.H_Props.dayType}
                    label="Tagesart"
                    onChange={(e) => {
                      setDateHours((data) => ({
                        ...data,
                        H_Props: { ...data.H_Props, dayType: e.target.value },
                      }));
                    }}
                  >
                    <MenuItem value={"-"}>Normal</MenuItem>
                    <MenuItem value={"auswaerts"}>Auswärtstag</MenuItem>
                    <MenuItem value={"anreise"}>Anreisetag</MenuItem>
                    <MenuItem value={"abreise"}>Abreisetag</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  label="Auslöse"
                  value={dayTypeTextfield}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </EqualStack>
              <TextField
                label="Notizen"
                helperText="Projekt, Ort, Fahrzeit als Fahrer"
                multiline
                rows={3}
                value={dateHours.H_Notes}
                onChange={(e) => {
                  setDateHours((data) => ({
                    ...data,
                    H_Notes: e.target.value,
                  }));
                }}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogDateHours(null)}>Abbrechen</Button>
            <Button onClick={submitForm} disabled={!valid}>
              Speichern
            </Button>
          </DialogActions>
        </>
      ) : (
        <></>
      )}
    </Dialog>
  );
}

export function dayTypeToText(dayType) {
  switch (dayType) {
    case "auswaerts":
      return "Auswärtstag";
    case "anreise":
      return "Anreisetag";
    case "abreise":
      return "Abreisetag";
    default:
      return "Normal";
  }
}

export function dayTypeToMoney(dayType) {
  switch (dayType) {
    case "auswaerts":
      return 28.0;
    case "anreise":
      return 14.0;
    case "abreise":
      return 14.0;
    default:
      return 0.0;
  }
}

export function calculateWorkingHours(startTime, endTime, breakDuration) {
  endTime = endTime.clone();

  //Calculation of work hours
  if (endTime.isBefore(startTime)) {
    endTime.add(1, "days");
  }

  let duration = moment
    .duration(endTime.diff(startTime))
    .subtract(breakDuration.hours(), "hours")
    .subtract(breakDuration.minutes(), "minutes");

  return duration.asHours();
}
