import React, { useEffect, useState } from "react";
import { setListener, emitEvent } from "../index.js";
import axios from "axios";
import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { Clear, Save, Upload } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";

const MemberDetailsPage = (props) => {
  const [member, setMember] = useState(props.member);

  const [inputFieldList, setInputFieldList] = useState([]);
  const [inputFieldValues, setInputFieldValues] = useState({});

  useEffect(() => {
    setListener("inputFieldsUpdated", function () {
      emitEvent("selectInputFieldsWithKeys", props.member.M_ID);
    });
    setListener("selectInputFieldsWithKeys", function (results) {
      results.forEach((inputField) => {
        if (inputField.input_type === "Textfeld") {
          setInputFieldValues((v) => ({
            ...v,
            [`${inputField.input_ID}_value`]: "",
          }));
          axios
            .get(
              "https://server.m-to-b.com:54545/requestMemberData/?key=" +
                inputField.fileurls[0] +
                "&token=" +
                props.currentUser.token
            )
            .then((result) => {
              setInputFieldValues((v) => ({
                ...v,
                [`${inputField.input_ID}_value`]: result.data,
              }));
            });
        }
      });
      setInputFieldList(results);
    });

    emitEvent("selectInputFieldsWithKeys", props.member.M_ID);
  }, [props.member.M_ID, props.currentUser.token]);

  const handleSubmit = (m) => {
    emitEvent("updateMember", m ?? member);
  };

  const saveInputField = (inputField) => {
    var file = null;
    if (inputField.input_type === "Textfeld") {
      file = new File(
        [inputFieldValues[`${inputField.input_ID}_value`]],
        "filename.txt",
        { type: "text/plain" }
      );
    } else if (inputField.input_type === "Datei") {
      file = inputFieldValues[`${inputField.input_ID}_value`];
    }

    const data = new FormData();
    data.append("file", file);
    data.append("input_ID", inputField.input_ID);
    data.append("input_type", inputField.input_type);
    data.append("input_title", inputField.input_title);
    data.append("M_ID", props.member.M_ID);

    const headers = {
      authorization: "Bearer " + props.currentUser.token,
    };

    axios
      .post("https://server.m-to-b.com:54545/upload", data, {
        headers: headers,
      })
      .then(() => {
        console.log("success");
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const deleteFile = (inputField, fileKey) => {
    if (window.confirm("Datei wirklich löschen?")) {
      let key = fileKey.split("/");

      emitEvent(
        "deleteFileFromInputField",
        props.member.M_ID,
        inputField.input_ID,
        key[key.length - 1]
      );
    }
  };

  let inputFields = inputFieldList.map((inputField) => {
    if (inputField.input_type === "Textfeld") {
      return (
        <TextField
          variant="outlined"
          key={inputField.input_ID}
          fullWidth
          label={inputField.input_title}
          value={inputFieldValues[`${inputField.input_ID}_value`]}
          onChange={(e) =>
            setInputFieldValues((v) => ({
              ...v,
              [`${inputField.input_ID}_value`]: e.target.value,
            }))
          }
          InputProps={{
            endAdornment: (
              <IconButton
                component="label"
                variant="contained"
                onClick={() => saveInputField(inputField)}
              >
                <Save />
              </IconButton>
            ),
          }}
        />
      );
    } else {
      let files = inputField.fileurls.map((fileurl, index) => {
        let filename = inputField.input_title + "_" + (index + 1);
        return (
          <Chip
            label={filename}
            key={filename}
            variant="outlined"
            onClick={() => {
              window.open(
                `https://server.m-to-b.com:54545/requestMemberData/?key=${fileurl}$download=1&token=${props.currentUser.token}`,
                "_blank"
              );
            }}
            onDelete={() => {
              deleteFile(inputField, fileurl);
            }}
          />
        );
      });

      return (
        <TextField
          variant="outlined"
          label={inputField.input_title}
          key={inputField.input_ID}
          fullWidth
          InputProps={{
            startAdornment: <>{files}</>,
            endAdornment: (
              <IconButton component="label" variant="contained">
                <Upload />
                <input
                  hidden
                  type="file"
                  name={`${inputField.input_ID}_value`}
                  onChange={(e) =>
                    setInputFieldValues(
                      (v) => ({
                        ...v,
                        [`${inputField.input_ID}_value`]: e.target.files[0],
                      }),
                      () => {
                        saveInputField(inputField);
                      }
                    )
                  }
                />
              </IconButton>
            ),
            readOnly: true,
          }}
        />
      );
    }
  });
  console.log(member);

  return (
    <div className="tab">
      <Stack direction="column" gap={2} width={"100%"}>
        <h2>Mitarbeiterübersicht</h2>
        <Stack direction={{ xs: "column", sm: "row" }} gap={2}>
          <TextField
            value={member.M_FirstName}
            label={"Vorname"}
            onChange={(e) =>
              setMember((m) => ({ ...m, M_FirstName: e.target.value }))
            }
            fullWidth
            InputProps={{
              readOnly: props.editMode,
              endAdornment: !props.editMode && (
                <IconButton
                  component="label"
                  variant="contained"
                  onClick={() => handleSubmit()}
                >
                  <Save />
                </IconButton>
              ),
            }}
          />
          <TextField
            value={member.M_LastName}
            label={"Nachname"}
            onChange={(e) =>
              setMember((m) => ({ ...m, M_LastName: e.target.value }))
            }
            fullWidth
            InputProps={{
              readOnly: props.editMode,
              endAdornment: !props.editMode && (
                <IconButton
                  component="label"
                  variant="contained"
                  onClick={() => handleSubmit()}
                >
                  <Save />
                </IconButton>
              ),
            }}
          />
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} gap={2}>
          <TextField
            value={member.M_Mail}
            label={"E-Mail"}
            onChange={(e) =>
              setMember((m) => ({ ...m, M_Mail: e.target.value }))
            }
            fullWidth
            InputProps={{
              readOnly: props.editMode,
              endAdornment: !props.editMode && (
                <IconButton
                  component="label"
                  variant="contained"
                  onClick={() => handleSubmit()}
                >
                  <Save />
                </IconButton>
              ),
            }}
          />
          <FormControl fullWidth>
            <InputLabel htmlFor="M_Super">Projektleiter</InputLabel>
            {
              <Select
                id={"M_Super"}
                name="M_Super"
                label={"Projektleiter"}
                value={member.M_Super}
                onChange={(e) => {
                  setMember((m) => {
                    handleSubmit({ ...m, M_Super: e.target.value });
                    return { ...m, M_Super: e.target.value };
                  });
                }}
                disabled={props.editMode}
              >
                <MenuItem key={0} value={0}>
                  Kein Projektleiter
                </MenuItem>
                {props.members
                  .filter((m) => m.M_ID !== member.M_ID)
                  .map((member) => {
                    return (
                      <MenuItem key={member.M_ID} value={member.M_ID}>
                        {member.M_FirstName + " " + member.M_LastName}
                      </MenuItem>
                    );
                  })}
              </Select>
            }
          </FormControl>
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} gap={2}>
          <DatePicker
            label="Austrittsdatum"
            mask="__.__.____"
            value={member.M_LeaveDate ? moment(member.M_LeaveDate) : null}
            onChange={(value) => {
              setMember((m) => {
                handleSubmit({
                  ...m,
                  M_LeaveDate: moment(value).format("YYYY-MM-DD"),
                });
                return {
                  ...m,
                  M_LeaveDate: moment(value).format("YYYY-MM-DD"),
                };
              });
            }}
            renderInput={(params) => (
              <TextField {...params} fullWidth variant="outlined" />
            )}
            InputAdornmentProps={{
              position: "start",
            }}
            InputProps={{
              endAdornment: !props.editMode && (
                <IconButton
                  sx={{ opacity: member.M_LeaveDate === null ? "0" : "1" }}
                  onClick={() =>
                    setMember((m) => {
                      handleSubmit({ ...m, M_LeaveDate: null });
                      return { ...m, M_LeaveDate: null };
                    })
                  }
                >
                  <Clear />
                </IconButton>
              ),
            }}
          />
        </Stack>
        {inputFields}
        {!props.editMode && (
          <FormControl>
            <FormLabel>Rechte:</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={member.M_EditSubs === 1}
                    onChange={(e) =>
                      setMember((m) => {
                        const newMember = {
                          ...m,
                          M_EditSubs: e.target.checked ? 1 : 0,
                        };
                        handleSubmit(newMember);

                        return newMember;
                      })
                    }
                    disabled={member.M_Admin === 1}
                  />
                }
                label="Kann Mitarbeiter bearbeiten"
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={member.M_EditSelf === 1}
                    onChange={(e) =>
                      setMember((m) => {
                        const newMember = {
                          ...m,
                          M_EditSelf: e.target.checked ? 1 : 0,
                        };
                        handleSubmit(newMember);

                        return newMember;
                      })
                    }
                    disabled={member.M_Admin === 1}
                  />
                }
                label="Kann sich selbst bearbeiten"
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={member.M_Admin === 1}
                    onChange={(e) => {
                      setMember((m) => {
                        const newMember = {
                          ...m,
                          M_EditSelf: 1,
                          M_EditSubs: 1,
                          M_Admin: e.target.checked ? 1 : 0,
                        };
                        handleSubmit(newMember);

                        return newMember;
                      });
                    }}
                  />
                }
                label="Administrator"
              />
            </FormGroup>
          </FormControl>
        )}
        {!props.editMode && (
          <Stack direction={{ xs: "column", sm: "row" }} gap={2}>
            <Button
              variant="outlined"
              onClick={() => props.setSelected("Mitarbeiter")}
            >
              Zurück
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                emitEvent("resetPassword", member.M_ID);
              }}
            >
              Passwort zurücksetzen
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                props.setSelected("ViewDateHours", {
                  member: props.member,
                });
              }}
            >
              Arbeitszeitnachweis
            </Button>
          </Stack>
        )}
      </Stack>
    </div>
  );
};

export default MemberDetailsPage;
