import React from "react";
import MenuItem from "./MenuItem.js";
import "./_TabNav.scss";
import companyImage from "./images/CompanyLogo.png";

class TabNav extends React.Component {

    constructor(props){
        super(props);

        this.state ={
            activeMenu : false
        }

        this.toggleMenu = this.toggleMenu.bind(this);
    }

    toggleMenu(){
        this.setState(function(prevState){
            return ({
                activeMenu : !prevState.activeMenu
            })
        });
    }
    
    render() {
        return(
            <>
                <nav className="mainMenu">
                    <div className="companyLogoWrapper">
                        <img src={companyImage} alt=""/>
                    </div>
                    <ul className={this.state.activeMenu ? "active" : ""}>
                        {this.props.tabs.map(tab => {
                            return (
                                <MenuItem key={tab} title={tab} isActive={tab === this.props.selected} setSelected={this.props.setSelected} toggleMenu={this.toggleMenu}/>
                            );
                        })}
                    </ul>
                    <div className={this.state.activeMenu ? "hamburgerMenu active" : "hamburgerMenu"} onClick={this.toggleMenu}>
                        <div className="bar1"></div>
                        <div className="bar2"></div>
                        <div className="bar3"></div>
                    </div>
                </nav>
            </>
        );
    }
}

export default TabNav;