import React, { Component } from "react";
import "./_ProjectList.scss"
import { FaEdit } from "react-icons/fa";
import { GrClose } from "react-icons/gr";
import { GrAdd } from "react-icons/gr";
import {emitEvent, shadeColor} from "../index.js";


class ProjectList extends Component{

    constructor(props){
        super(props);
        this.handleDelete = this.handleDelete.bind(this);
    }


    handleDelete(project){
        if(window.confirm(project.P_Name + " wirklich löschen?")){
            emitEvent("deleteProject", project);
            this.props.projectsChanged();
            this.props.setSelected("Projekte");
        }
    }

    render(){

        let projectListItems = this.props.projects.map(project => {
            return (
                <div className="projectListItem" key={project.P_ID}>
                    <div className="projectListText">{project.P_Name}</div>
                    <div className="projectListCircle" style={{background: project.P_Color}}>
                        <div className="projectListCircleSecondHalf" style={{background: shadeColor(project.P_Color, -16)}}></div>
                    </div>
                    <div className="projectListIcon projectListEdit" onClick={() => this.props.setSelected("EditProject", project)}><FaEdit/></div>
                    <div className="projectListIcon projectListRemove"onClick={() => this.handleDelete(project)}><GrClose/></div>
                </div>
            );
        });

        return (
            <div className="projectList">
                {projectListItems}
                <li className="projectListItem addProject" onClick={() => this.props.setSelected("EditProject")}>
                    <div className="projectListIcon projectListAdd">
                        <GrAdd />
                    </div>
                </li>
            </div>
        );
    }
}

export default ProjectList;
