import React, { Component } from "react";
import { emitEvent } from "../index.js";
import "./_Tab.scss";
import env from "react-dotenv";


class LoginForm extends Component{
    
    constructor(props){
        super(props);

        this.state = {
            M_FirstName : "",
            M_LastName : "",
            M_Pass : ""
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    
    }
    
    handleSubmit(e){
        e.preventDefault();
        console.log(env.COMPANY);
        emitEvent("login", env.COMPANY, this.state.M_FirstName, this.state.M_LastName, this.state.M_Pass);

    }

    handleChange(event){
        this.setState({
            [event.target.name] : event.target.value
        });
    }
    
    render(){
        return (
            <div className="tab">
                <form className="content medium">
                    <div className="row">
                        <div className="inputText">Vorname:</div>
                        <input type="textfield" name="M_FirstName" value={this.state.M_FirstName} onChange={this.handleChange}></input>
                    </div>
                    <div className="row">
                        <div className="inputText">Nachname:</div>
                        <input type="textfield" name="M_LastName" value={this.state.M_LastName} onChange={this.handleChange}></input>
                    </div>
                    <div className="row">
                        <div className="inputText">Passwort:</div>
                        <input type="password" name="M_Pass" value={this.state.M_Pass} onChange={this.handleChange}></input>
                    </div>
                    {
                        this.props.failed ?
                        <div className="row">
                            <div className="inputText" style={{color: "#ff0000"}}>Login fehlgeschlagen!</div>
                        </div> 
                        :
                        ""
                    } 
                    <div className="row horizontal">
                        <button onClick={this.handleSubmit}>Login</button>
                    </div>   
                </form>
            </div>
        );
    }

}

export default LoginForm;