import React, { Component } from "react";
import CellEntry from "./CellEntry.js";

class WeekRow extends Component {
  render() {
    let cellEntries = [];

    //Push WeekSpecial
    var special = true;
    cellEntries.push(
      <CellEntry
        scrollToCurrentWeek={this.props.scrollToCurrentWeek}
        key={`${this.props.dates[0].T_Date}-1`}
        special={special}
        date={this.props.dates[0]}
        selectedList={this.props.selectedList}
        projects={this.props.projects}
        darker={this.props.darker}
        connected={this.props.connected}
        changeSelection={this.props.changeSelection}
        allowedToEdit={this.props.allowedToEdit}
        pasteProjectToSelected={this.props.pasteProjectToSelected}
        deleteSelected={this.props.deleteSelected}
        openDialogue={this.props.openDialogue}
        changeSelectionBox={this.props.changeSelectionBox}
        copySelected={this.props.copySelected}
        pasteFromClipboard={this.props.pasteFromClipboard}
      />
    );

    special = false;

    for (var i = 1; i < 8; i++) {
      cellEntries.push(
        <CellEntry
          scrollToCurrentWeek={this.props.scrollToCurrentWeek}
          key={`${this.props.dates[i].T_Date}-${this.props.dates[i].M_ID}-${this.props.dates[i].T_WeekInfo}`}
          special={special}
          date={this.props.dates[i]}
          selectedList={this.props.selectedList}
          projects={this.props.projects}
          darker={this.props.darker}
          connected={this.props.connected}
          changeSelection={this.props.changeSelection}
          allowedToEdit={this.props.allowedToEdit}
          pasteProjectToSelected={this.props.pasteProjectToSelected}
          deleteSelected={this.props.deleteSelected}
          openDialogue={this.props.openDialogue}
          changeSelectionBox={this.props.changeSelectionBox}
          copySelected={this.props.copySelected}
          pasteFromClipboard={this.props.pasteFromClipboard}
        />
      );
    }

    return <>{cellEntries}</>;
  }
}

export default WeekRow;
