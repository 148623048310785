
import React, { Component } from "react";

class ConnectionCheck extends Component{
    render(){

        const style = this.props.connected ? {color: "#00ff00"} : {color: "#ff0000"}

        return(
            <div className="connectionCheck" style={style}>
                {this.props.connected ? "Verbunden" : "Nicht verbunden"}   
            </div>
        )
    }
}
 

export default ConnectionCheck;